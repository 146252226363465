<template>
  <div class="my-content-box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }"
        ><span class="iconfont icon-home"></span
      ></el-breadcrumb-item>
      <el-breadcrumb-item>主页管理</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-table :data="images">
        <el-table-column
          label="序号"
          type="index"
          width="80px"
        ></el-table-column>
        <el-table-column label="展示图" prop="displayImage">
          <template slot-scope="scope">
            <img width="720px" :src="'/images/' + scope.row.displayImage" />
          </template>
        </el-table-column>
        <el-table-column align="right" width="300px">
          <template slot="header">
            <el-button
              icon="el-icon-plus"
              type="primary"
              size="mini"
              @click="createCarouselImage"
            ></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
              circle
              size="mini"
              type="success"
              icon="el-icon-top"
              :disabled="scope.row.orderInCarousel === 1"
              @click="goUp(scope.row.id)"
            ></el-button>
            <el-button
              circle
              size="mini"
              type="success"
              icon="el-icon-bottom"
              :disabled="scope.row.orderInCarousel === images.length"
              @click="goDown(scope.row.id)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="deleteCarouselImage(scope.row.id, scope.row.name)"
            ></el-button>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="editCarouselImage(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      images: []
    }
  },
  created () {
    this.getCarouselImages()
  },
  methods: {
    createCarouselImage () {
      this.$router.push('/carousel-images/new')
    },
    editCarouselImage (id) {
      this.$router.push(`/carousel-images/${id}`)
    },
    goUp (id) {
      this.$http
        .patch(`/carousel-images/${id}/up`)
        .then(() => {
          this.getCarouselImages()
        })
        .catch(() => {
          this.getCarouselImages()
        })
    },
    goDown (id) {
      this.$http
        .patch(`/carousel-images/${id}/down`)
        .then(() => {
          this.getCarouselImages()
        })
        .catch(() => {
          this.getCarouselImages()
        })
    },
    deleteCarouselImage (id, name) {
      this.$confirm(`即将删除 ${name}`, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .delete(`/carousel-images/${id}`)
            .then(() => {
              this.$message.success('删除成功')
              this.getCarouselImages()
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {
          this.$message.info('取消删除操作')
        })
    },
    async getCarouselImages () {
      const { data: res } = await this.$http.get('/carousel-images')
      this.images = res
    }
  }
}
</script>

<style lang="less" scoped>
.my-content-box {
  width: 1200px;
  margin: 0 auto;
}
.el-breadcrumb {
  margin: 10px;
  font-size: 0.8em;
}
</style>
