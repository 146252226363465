<template>
  <div class="my-content-box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }"
        ><span class="iconfont icon-home"></span
      ></el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>产品系列</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-table :data="families">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-button
              v-for="item in scope.row.subFamilies"
              :key="item.orderInFamily"
              size="mini"
            >
              {{ item.name }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="序号"
          type="index"
          width="80px"
        ></el-table-column>
        <el-table-column label="展示图" prop="displayImage">
          <template slot-scope="scope">
            <el-image
              style="width: 180px"
              :src="'/images/' + scope.row.displayImage"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="描述" prop="description"></el-table-column>
        <el-table-column align="right">
          <template slot="header">
            <el-button
              icon="el-icon-plus"
              type="primary"
              size="mini"
              @click="createProductFamily"
            ></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
              circle
              size="mini"
              type="success"
              icon="el-icon-top"
              :disabled="scope.row.orderInFamily === 1"
              @click="goUp(scope.row.id)"
            ></el-button>
            <el-button
              circle
              size="mini"
              type="success"
              icon="el-icon-bottom"
              :disabled="scope.row.orderInFamily === families.length"
              @click="goDown(scope.row.id)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="deleteProductFamily(scope.row.id, scope.row.name)"
            ></el-button>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="editProductFamily(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      families: []
    }
  },
  created () {
    this.getProductFamilies()
  },
  methods: {
    createProductFamily () {
      this.$router.push('/product-families/new')
    },
    editProductFamily (id) {
      this.$router.push(`/product-families/${id}`)
    },
    goUp (id) {
      this.$http
        .patch(`/product-families/${id}/up`)
        .then(() => {
          this.getProductFamilies()
        })
        .catch(() => {
          this.getProductFamilies()
        })
    },
    goDown (id) {
      this.$http
        .patch(`/product-families/${id}/down`)
        .then(() => {
          this.getProductFamilies()
        })
        .catch(() => {
          this.getProductFamilies()
        })
    },
    deleteProductFamily (id, name) {
      this.$confirm(`即将删除 ${name}`, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .delete(`/product-families/${id}`)
            .then(() => {
              this.$message.success('删除成功')
              this.getProductFamilies()
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {
          this.$message.info('取消删除操作')
        })
    },
    async getProductFamilies () {
      const { data: res } = await this.$http.get('/product-families')
      this.families = res
    }
  }
}
</script>

<style lang="less" scoped>
.my-content-box {
  width: 1200px;
  margin: 0 auto;
}
.el-breadcrumb {
  margin: 10px;
  font-size: 0.8em;
}
</style>
