import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from '../views/NotFound.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Main from '../views/Main.vue'
import UserSetting from '../views/UserSetting.vue'
import ProductFamily from '../views/ProductFamily.vue'
import ProductFamilyNew from '../views/ProductFamilyNew.vue'
import ProductFamilyEdit from '../views/ProductFamilyEdit.vue'
import CarouselImage from '../views/CarouselImage.vue'
import CarouselImageNew from '../views/CarouselImageNew.vue'
import CarouselImageEdit from '../views/CarouselImageEdit.vue'
import Product from '../views/Product.vue'
import ProductNew from '../views/ProductNew.vue'
import ProductEdit from '../views/ProductEdit.vue'
import News from '../views/News.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/login', component: Login },
  {
    path: '/main',
    component: Main,
    children: [
      { path: '/home', component: Home },
      { path: '/user-settings', component: UserSetting },
      { path: '/product-families', component: ProductFamily },
      { path: '/product-families/new', component: ProductFamilyNew },
      { path: '/product-families/:id', component: ProductFamilyEdit },
      { path: '/carousel-images', component: CarouselImage },
      { path: '/carousel-images/new', component: CarouselImageNew },
      { path: '/carousel-images/:id', component: CarouselImageEdit },
      { path: '/products', component: Product },
      { path: '/products/new', component: ProductNew },
      { path: '/products/:id', component: ProductEdit },
      { path: '/news', component: News }
    ]
  },
  { path: '*', component: NotFound }
]

const router = new VueRouter({
  routes
})

export default router
