<script>
const ExceptionHandler = (err, router, message) => {
  if (err && err.response) {
    switch (err.response.status) {
      case 401:
        router.push('/login')
        break
      case 404:
        router.push('/404')
        break
      default:
        message.error('未知错误')
    }
  }
}

export default {
  ExceptionHandler
}
</script>
