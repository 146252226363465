<template>
  <div class="my-content-box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }"
        ><span class="iconfont icon-home"></span
      ></el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/product-families' }"
        >产品系列</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{
        this.editingProductFamily.name
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-tabs tab-position="left" v-model="activePane">
        <el-tab-pane name="basic" label="基本信息">
          <div class="my-form">
            <el-form
              ref="editingProductFamilyForm"
              :model="editingProductFamily"
              :rules="editingProductFamilyRules"
            >
              <el-form-item label="展示图" prop="displayImage">
                <el-upload
                  class="my-upload"
                  action="https://no-such-server"
                  accept=".png,.jpg"
                  :http-request="onUpload"
                  :show-file-list="false"
                >
                  <el-button
                    class="my-upload-button"
                    type="primary"
                    size="mini"
                    icon="el-icon-upload"
                    >上传</el-button
                  >
                </el-upload>
                <img
                  class="my-display-image"
                  v-if="editingProductFamily.displayImage"
                  :src="'/images/' + editingProductFamily.displayImage"
                />
              </el-form-item>
              <el-form-item label="名称" prop="name">
                <el-input v-model="editingProductFamily.name"></el-input>
              </el-form-item>
              <el-form-item label="简介" prop="description">
                <el-input
                  v-model="editingProductFamily.description"
                  type="textarea"
                  :rows="5"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="update">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane name="sub" label="子系列">
          <div class="my-subs">
            <el-table :data="editingProductFamily.subFamilies">
              <el-table-column label="名称" prop="name"> </el-table-column>
              <el-table-column align="right">
                <template slot="header">
                  <el-button
                    icon="el-icon-plus"
                    type="primary"
                    size="mini"
                    @click="createSubFamily"
                  ></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button
                    circle
                    size="mini"
                    type="success"
                    icon="el-icon-top"
                    :disabled="scope.row.orderInFamily === 1"
                    @click="goUp(scope.row.familyId, scope.row.id)"
                  ></el-button>
                  <el-button
                    circle
                    size="mini"
                    type="success"
                    icon="el-icon-bottom"
                    :disabled="
                      scope.row.orderInFamily ===
                      editingProductFamily.subFamilies.length
                    "
                    @click="goDown(scope.row.familyId, scope.row.id)"
                  ></el-button>
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    @click="
                      deleteSubFamily(
                        scope.row.familyId,
                        scope.row.id,
                        scope.row.name
                      )
                    "
                  ></el-button>
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-edit"
                    @click="editSubFamily(scope.row.familyId, scope.row.id)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-dialog
      title="新增子系列"
      :visible.sync="dialogNewVisible"
      @close="dialogNewClosed"
      width="450px"
    >
      <el-form
        :model="newSubFamily"
        ref="newSubFamilyForm"
        :rules="newSubFamilyFormRules"
        label-width="80px"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="newSubFamily.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogNewVisible = false">取消</el-button>
        <el-button type="primary" @click="addSubFamily">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改子系列"
      :visible.sync="dialogEditVisible"
      @close="dialogEditClosed"
      width="450px"
    >
      <el-form
        :model="editingSubFamily"
        ref="editingSubFamilyForm"
        :rules="editingSubFamilyFormRules"
        label-width="80px"
      >
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="editingSubFamily.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogEditVisible = false">取消</el-button>
        <el-button type="primary" @click="updateSubFamily">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Func from '../commons/Function.vue'

export default {
  data () {
    const NameValidator = (rule, value, callback) => {
      if (!value) {
        callback()
      }
      this.$http
        .post(`/product-families/${this.newSubFamily.familyId}/sub-by-name`, {
          name: value
        })
        .then((res) => {
          if (res.data) {
            callback(new Error('已存在同名子系列'))
          } else {
            callback()
          }
        })
        .catch(() => {
          callback(new Error('网络错误'))
        })
    }
    const BrotherNameValidator = (rule, value, callback) => {
      if (!value) {
        callback()
      }
      this.$http
        .post(
          `/product-families/${this.editingSubFamily.familyId}/sub-by-name`,
          { name: value }
        )
        .then((res) => {
          if (res.data) {
            const subFamily = res.data
            if (subFamily.id === this.editingSubFamily.id) {
              callback()
            }
            callback(new Error('已存在同名子系列'))
          } else {
            callback()
          }
        })
        .catch(() => {
          callback(new Error('网络错误'))
        })
    }
    return {
      activePane: 'basic',
      dialogNewVisible: false,
      dialogEditVisible: false,
      editingProductFamily: {
        displayImage: '',
        name: '',
        description: ''
      },
      editingProductFamilyRules: {
        displayImage: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        description: [
          { required: true, message: '请输入简介', trigger: 'blur' }
        ]
      },
      newSubFamily: {
        familyId: 0,
        name: ''
      },
      newSubFamilyFormRules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { validator: NameValidator, trigger: 'blur' }
        ]
      },
      editingSubFamily: {},
      editingSubFamilyFormRules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { validator: BrotherNameValidator, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getProductFamily(this.$route.params.id)
  },
  methods: {
    async getProductFamily (id) {
      await this.$http
        .get(`/product-families/${id}`)
        .then((res) => {
          this.editingProductFamily = res.data
        })
        .catch((err) => {
          Func.ExceptionHandler(err, this.$router, this.$message)
        })
    },
    onUpload (file) {
      const formData = new FormData()
      formData.append('file', file.file)
      this.$http
        .post('/images', formData)
        .then((res) => {
          this.editingProductFamily.displayImage = res.data
          this.$message.success('上传成功')
          this.$refs.editingProductFamilyForm.validateField('displayImage')
        })
        .catch((err) => {
          this.$message.error(`${err.response.data.message}`)
        })
    },
    update () {
      this.$refs.editingProductFamilyForm
        .validate()
        .then(() => {
          const id = this.editingProductFamily.id
          this.$http
            .patch(`/product-families/${id}`, this.editingProductFamily)
            .then(() => {
              this.$message.success('修改成功')
            })
            .catch((err) => {
              this.$message.error(`${err.response.data.message}`)
            })
        })
        .catch(() => {})
    },
    createSubFamily () {
      this.newSubFamily.familyId = this.editingProductFamily.id
      this.newSubFamily.name = ''
      this.dialogNewVisible = true
    },
    async editSubFamily (familyId, id) {
      await this.$http
        .get(`/product-families/${familyId}/sub/${id}`)
        .then((res) => {
          this.editingSubFamily = res.data
          this.dialogEditVisible = true
        })
        .catch(() => {})
    },
    dialogNewClosed () {
      this.newSubFamily.name = ''
      this.$refs.newSubFamilyForm.resetFields()
    },
    dialogEditClosed () {
      this.$refs.editingSubFamilyForm.resetFields()
    },
    goUp (familyId, id) {
      this.$http
        .patch(`/product-families/${familyId}/sub/${id}/up`)
        .then(() => {
          this.getProductFamily(this.$route.params.id)
        })
        .catch(() => {
          this.getProductFamily(this.$route.params.id)
        })
    },
    goDown (familyId, id) {
      this.$http
        .patch(`/product-families/${familyId}/sub/${id}/down`)
        .then(() => {
          this.getProductFamily(this.$route.params.id)
        })
        .catch(() => {
          this.getProductFamily(this.$route.params.id)
        })
    },
    addSubFamily () {
      this.$refs.newSubFamilyForm
        .validate()
        .then(() => {
          this.$http
            .post(
              `/product-families/${this.newSubFamily.familyId}/sub`,
              this.newSubFamily
            )
            .then(() => {
              this.dialogNewVisible = false
              this.$message.success('新增成功')
              this.getProductFamily(this.$route.params.id)
            })
            .catch((err) => {
              this.$message.error(`新增失败：${err.response.data.message}`)
            })
        })
        .catch(() => {})
    },
    updateSubFamily () {
      this.$refs.editingSubFamilyForm
        .validate()
        .then(() => {
          this.$http
            .patch(
              `/product-families/${this.editingSubFamily.familyId}/sub/${this.editingSubFamily.id}`,
              this.editingSubFamily
            )
            .then(() => {
              this.dialogEditVisible = false
              this.$message.success('修改成功')
              this.getProductFamily(this.$route.params.id)
            })
            .catch((err) => {
              this.$message.error(`修改失败：${err.response.data.message}`)
            })
        })
        .catch(() => {})
    },
    deleteSubFamily (familyId, id, name) {
      this.$confirm(`即将删除 ${name}`, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .delete(`/product-families/${familyId}/sub/${id}`)
            .then(() => {
              this.$message.success('删除成功')
              this.getProductFamily(this.$route.params.id)
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {
          this.$message.info('取消删除操作')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.my-content-box {
  width: 1200px;
  margin: 0 auto;
}
.el-breadcrumb {
  margin: 10px;
  font-size: 0.8em;
}
.my-form {
  width: 300px;
  margin-left: 100px;
}
.my-subs {
  margin-left: 100px;
  width: 600px;
}
.my-upload-button {
  margin-left: 15px;
}
.my-display-image {
  width: 300px;
}
</style>
