<template>
  <div class="my-content-box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }"
        ><span class="iconfont icon-home"></span
      ></el-breadcrumb-item>
      <el-breadcrumb-item>用户设置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-tabs tab-position="left" v-model="avtivePane">
        <el-tab-pane name="0" label="基本信息">
          <div class="el-form-box">
            <el-form
              ref="basicInfoForm"
              :model="editingUser"
              label-width="120px"
            >
              <el-form-item label="用户名" prop="username">
                <el-input
                  v-model="editingUser.username"
                  readonly
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="姓名" prop="name">
                <el-input
                  v-model="editingUser.name"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="电话" prop="phoneNumber">
                <el-input
                  v-model="editingUser.phoneNumber"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input
                  v-model="editingUser.email"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="updateBasicInfo"
                  >确定</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane name="1" label="我的头像">
          <div class="avatar-setting">
            <div class="my-avatar-box" v-show="showMyAvatar">
              <div>
                <img
                  :src="
                    editingUser.avatar
                      ? `/images/${editingUser.avatar}`
                      : '/avatar.png'
                  "
                />
              </div>
              <el-button
                class="btn-change-avatar"
                type="primary"
                size="small"
                @click="showMyAvatar = false"
                >更换头像</el-button
              >
            </div>
            <div class="cropper-box" v-show="!showMyAvatar">
              <vueCropper
                ref="cropper"
                class="cropper"
                :img="option.img"
                :outputSize="option.size"
                :outputType="option.outputType"
                :info="true"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :fixed="option.fixed"
              ></vueCropper>
              <div class="buttons">
                <el-upload
                  ref="uploader"
                  action="https://no-such-server"
                  accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG"
                  :multiple="false"
                  :auto-upload="false"
                  :show-file-list="false"
                  :on-change="uploaderChanged"
                >
                  <el-button
                    class="uploader-button"
                    slot="trigger"
                    size="small"
                    type="primary"
                    >打开图片</el-button
                  >
                </el-upload>
                <el-button
                  size="small"
                  icon="el-icon-zoom-in"
                  @click="changeScale(1)"
                ></el-button>
                <el-button
                  size="small"
                  icon="el-icon-zoom-out"
                  @click="changeScale(-1)"
                ></el-button>
                <el-button
                  size="small"
                  icon="el-icon-refresh-left"
                  @click="rotateLeft"
                ></el-button>
                <el-button
                  size="small"
                  icon="el-icon-refresh-right"
                  @click="rotateRight"
                ></el-button>
                <el-button size="small" type="primary" @click="updateAvatar"
                  >上传</el-button
                >
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane name="2" label="修改密码">
          <div class="el-form-box">
            <el-form
              ref="passwordForm"
              :model="editingPassword"
              :rules="passwordRules"
              label-width="120px"
            >
              <el-form-item label="新密码" prop="password">
                <el-input
                  type="password"
                  v-model="editingPassword.password"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="checkPassword">
                <el-input
                  type="password"
                  v-model="editingPassword.checkPassword"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="updatePassword"
                  >确定</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import Func from '../commons/Function.vue'

export default {
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.editingPassword.checkPassword !== '') {
          this.$refs.passwordForm.validateField('checkPassword')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.editingPassword.password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {
      editingUser: {
        name: '',
        nickName: ''
      },
      editingPassword: {
        password: '',
        checkPassword: ''
      },
      passwordRules: {
        password: [
          { validator: validatePass, trigger: 'blur' },
          { min: 6, message: '长度最少6位', trigger: 'blur' },
          { max: 20, message: '长度不能超过20位', trigger: 'blur' }
        ],
        checkPassword: [{ validator: validatePass2, trigger: 'blur' }]
      },
      avtivePane: '0',
      showMyAvatar: true,
      option: {
        img: '',
        outputSize: 1,
        full: false,
        outputType: 'png',
        canMove: true,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 150,
        autoCropHeight: 150,
        fixed: true
      }
    }
  },
  created () {
    this.getEditingUser()
  },
  methods: {
    async getEditingUser () {
      await this.$http
        .get('/users/me')
        .then((res) => {
          this.editingUser = res.data
        })
        .catch((err) => {
          Func.ExceptionHandler(err, this.$router, this.$message)
        })
    },
    async updateBasicInfo () {
      await this.$http
        .patch(`/users/${this.editingUser.id}`, this.editingUser)
        .then(() => {
          this.$message.success('修改成功')
        })
        .catch((err) => {
          this.$message.error(`${err.response.data.message}`)
        })
    },
    updateAvatar () {
      if (!(this.option.img.length > 10)) {
        this.$message.error('请打开图片')
        return
      }
      this.$refs.cropper.getCropBlob((data) => {
        var formData = new FormData()
        formData.append('file', data, 'avatar.png')
        this.$http
          .post('/images', formData)
          .then((res) => {
            this.editingUser.avatar = res.data
            this.updateBasicInfo()
            this.$router.go(0)
          })
          .catch((err) => {
            this.$message.error(`${err.response.data.message}`)
          })
      })
    },
    async doUpdatePassword () {
      const id = this.editingUser.id
      await this.$http
        .patch(`/users/${id}/password`, {
          id: id,
          password: this.editingPassword.password
        })
        .then(() => {
          this.$message.success('修改密码成功')
          localStorage.clear()
          this.$router.push('/login')
        })
        .catch((err) => {
          this.$message.error(`${err.response.data.message}`)
        })
    },
    async updatePassword () {
      this.$refs.passwordForm
        .validate()
        .then(() => {
          this.doUpdatePassword()
        })
        .catch(() => {})
    },
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    uploaderChanged (file, fileList) {
      const isIMAGE =
        file.raw.type === 'image/jpeg' || file.raw.type === 'image/png'
      const isLt1M = file.size / 1024 / 1024 < 1
      if (!isIMAGE) {
        this.$message.error('只能上传 jpg/png 图片')
        return false
      }
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 1MB')
        return false
      }
      this.option.img = URL.createObjectURL(file.raw)
    },
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight () {
      this.$refs.cropper.rotateRight()
    }
  },
  watch: {
    avtivePane () {
      this.showMyAvatar = true
    }
  }
}
</script>

<style lang="less" scoped>
.my-content-box {
  width: 1200px;
  margin: 0 auto;
}
.el-breadcrumb {
  margin: 10px;
  font-size: 0.8em;
}
.el-card {
  height: 480px;
}
.grid-content {
  min-height: 36px;
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}
.avatar-setting {
  margin-left: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.my-avatar-box {
  display: flex;
  flex-direction: column;
}
.el-form-box {
  width: 480px;
}
.roles {
  margin-left: 120px;
  width: 360px;
  display: flex;
  flex-wrap: wrap;
}
.my-avatar-box {
  img {
    width: 360px;
    height: 360px;
  }
}
.cropper-box {
  .cropper {
    width: 360px;
    height: 360px;
  }
}
.buttons {
  margin-top: 12px;
  display: flex;
}
.uploader-button {
  margin-right: 10px;
}
.btn-change-avatar {
  margin-top: 8px;
}
</style>
