<template>
  <div class="my-content-box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }"
        ><span class="iconfont icon-home"></span
      ></el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>产品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-table :data="products">
        <el-table-column
          label="序号"
          type="index"
          width="80px"
        ></el-table-column>
        <el-table-column label="产品名称" prop="name" width="360px"></el-table-column>
        <el-table-column label="所属系列" prop="familyName"></el-table-column>
        <el-table-column label="子系列" prop="subFamilyName"></el-table-column>
        <el-table-column align="right">
          <template slot="header">
            <el-button
              icon="el-icon-plus"
              type="primary"
              size="mini"
              @click="createProduct()"
            ></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="deleteProduct(scope.row.id, scope.row.name)"
            ></el-button>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="editProduct(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="my-footer">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          @size-change="sizeChangedHandler"
          @current-change="currentChangedHandler"
          :page-size="queryInfo.size"
          :current-page="queryInfo.current"
          :total="queryInfo.total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      products: [],
      queryInfo: {
        size: 20,
        current: 1,
        total: 0
      }
    }
  },
  created () {
    this.getProducts()
  },
  methods: {
    editProduct (id) {
      this.$router.push(`/products/${id}`)
    },
    createProduct () {
      this.$router.push('/products/new')
    },
    deleteProduct (id, name) {
      this.$confirm(`即将删除 ${name}`, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .delete(`/products/${id}`)
            .then(() => {
              this.$message.success('删除成功')
              this.getProducts()
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {
          this.$message.info('取消删除操作')
        })
    },
    async getProducts () {
      const { data: res } = await this.$http.get('/products', {
        params: {
          size: this.queryInfo.size,
          current: this.queryInfo.current
        }
      })
      this.products = res.records
      this.queryInfo.total = res.total
    },
    sizeChangedHandler (newSize) {
      this.queryInfo.size = newSize
      this.getProducts()
    },
    currentChangedHandler (current) {
      this.queryInfo.current = current
      this.getProducts()
    }
  }
}
</script>

<style lang="less" scoped>
.my-content-box {
  width: 1200px;
  margin: 0 auto;
}
.el-breadcrumb {
  margin: 10px;
  font-size: 0.8em;
}
.my-footer {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
</style>
