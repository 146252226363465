<template>
  <div>
    <el-container>
      <el-header>
        <div class="my-header-inner-box">
          <div class="logo" @click="logoClicked">
            <img class="logo-img" src="../assets/images/logo.png" />
          </div>
          <el-menu
            class="menu"
            mode="horizontal"
            text-color="#999"
            active-text-color="#333"
            :unique-opened="true"
            :router="true"
            :default-active="active"
          >
            <el-submenu index="home">
              <template slot="title">主页管理</template>
              <el-menu-item index="/carousel-images">轮播图</el-menu-item>
              <el-menu-item index="/news">新闻资讯</el-menu-item>
            </el-submenu>
            <el-submenu index="products">
              <template slot="title">产品管理</template>
              <el-menu-item index="/product-families">产品系列</el-menu-item>
              <el-menu-item index="/products">产品列表</el-menu-item>
            </el-submenu>
          </el-menu>
          <el-dropdown @command="handleCommand">
            <el-avatar
              shape="square"
              fit="contain"
              :src="avatarUrl"
            ></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-setting" command="setting"
                >设置</el-dropdown-item
              >
              <el-dropdown-item icon="el-icon-switch-button" command="exit"
                >退出</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
        <router-view name="welcome"></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Func from '../commons/Function.vue'

export default {
  data () {
    return {
      active: '',
      user: {},
      avatarUrl: ''
    }
  },
  created () {
    this.setActive(this.$route.path)
    this.getUserInfo()
  },
  watch: {
    user (user) {
      if (user.avatar) {
        this.avatarUrl = `/images/${user.avatar}`
      } else {
        this.avatarUrl = '/avatar.png'
      }
    },
    '$route.path': function (newVal) {
      this.setActive(newVal)
    }
  },
  methods: {
    setActive (path) {
      var slash = path.lastIndexOf('/')
      if (slash === 0) {
        this.active = path
      } else if (slash > 0) {
        this.active = '/' + path.split('/')[1]
      } else {
        this.active = '/home'
      }
    },
    async getUserInfo () {
      await this.$http
        .get('/users/me')
        .then((res) => {
          this.user = res.data
        })
        .catch((err) => {
          Func.ExceptionHandler(err, this.$router, this.$message)
        })
    },
    logoClicked () {
      this.$router.push('/home')
    },
    handleCommand (command) {
      switch (command) {
        case 'exit':
          this.exit()
          break
        case 'setting':
          this.gotoUserSetting()
          break
        default:
          break
      }
    },
    gotoUserSetting () {
      this.$router.push('/user-settings')
    },
    exit () {
      localStorage.clear()
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
.el-header {
  padding: 0;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px #e0e0e0;
}
.my-header-inner-box {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.el-main {
  padding: 0;
}
.el-avatar {
  width: 45px;
  height: 45px;
  transform: translateY(1.5px);
  flex-grow: 0;
  cursor: pointer;
}
.avatar {
  width: 100%;
  height: 100%;
}
.logo {
  width: 150px;
  font-size: 24px;
  flex-grow: 0;
  margin-right: 15px;
  margin-top: 5px;
  cursor: pointer;
}
.logo-img {
  width: 100%;
}
.menu {
  flex-grow: 1;
  font-weight: 600;
}
</style>

<style>
.el-submenu__title {
  border-bottom-color: #FFF !important;
}
</style>
