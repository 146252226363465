<template>
  <div class="my-content-box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }"
        ><span class="iconfont icon-home"></span
      ></el-breadcrumb-item>
      <el-breadcrumb-item>主页管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/carousel-images' }"
        >轮播图</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{
        this.editingCarouselImage.orderInCarousel
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="my-form-box">
        <div class="my-form">
          <el-form
            ref="editingCarouselImageForm"
            :model="editingCarouselImage"
            :rules="editingCarouselImageRules"
          >
            <el-form-item label="展示图" prop="displayImage">
              <el-upload
                class="my-upload"
                action="https://no-such-server"
                accept=".png,.jpg"
                :http-request="onUpload"
                :show-file-list="false"
              >
                <el-button
                  class="my-upload-button"
                  type="primary"
                  size="mini"
                  icon="el-icon-upload"
                  >上传</el-button
                >
              </el-upload>
              <img
                class="my-display-image"
                v-if="editingCarouselImage.displayImage"
                :src="'/images/' + editingCarouselImage.displayImage"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="update">确定</el-button>
              <el-button @click="cancel">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import Func from '../commons/Function.vue'

export default {
  data () {
    return {
      editingCarouselImage: {
        displayImage: ''
      },
      editingCarouselImageRules: {
        displayImage: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.getCarouselImage(this.$route.params.id)
  },
  methods: {
    async getCarouselImage (id) {
      await this.$http
        .get(`/carousel-images/${id}`)
        .then((res) => {
          this.editingCarouselImage = res.data
        })
        .catch((err) => {
          Func.ExceptionHandler(err, this.$router, this.$message)
        })
    },
    onUpload (file) {
      const formData = new FormData()
      formData.append('file', file.file)
      this.$http
        .post('/images', formData)
        .then((res) => {
          this.editingCarouselImage.displayImage = res.data
          this.$message.success('上传成功')
          this.$refs.editingCarouselImageForm.validateField('displayImage')
        })
        .catch((err) => {
          this.$message.error(`${err.response.data.message}`)
        })
    },
    cancel () {
      this.$router.go(-1)
    },
    update () {
      this.$refs.editingCarouselImageForm
        .validate()
        .then(() => {
          const id = this.editingCarouselImage.id
          this.$http
            .patch(`/carousel-images/${id}`, this.editingCarouselImage)
            .then(() => {
              this.$message.success('修改成功')
              this.$router.go(-1)
            })
            .catch((err) => {
              this.$message.error(`${err.response.data.message}`)
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.my-content-box {
  width: 1200px;
  margin: 0 auto;
}
.el-breadcrumb {
  margin: 10px;
  font-size: 0.8em;
}
.my-form-box {
  display: flex;
  justify-content: center;
}
.my-form {
  width: 600px;
}
.my-upload-button {
  margin-left: 15px;
}
.my-display-image {
  width: 600px;
}
</style>
