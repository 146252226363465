<template>
  <div class="my-content-box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }"
        ><span class="iconfont icon-home"></span
      ></el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/products' }"
        >产品列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ this.editingProduct.name }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-tabs tab-position="left" v-model="activePane">
        <el-tab-pane name="basic" label="基本信息">
          <div class="my-form">
            <el-form
              ref="editingProductForm"
              :model="editingProduct"
              :rules="editingProductRules"
            >
              <el-form-item label="所属系列" prop="familyName">
                <el-input
                  v-model="editingProduct.familyName"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item label="子系列" prop="subFamilyName">
                <el-input
                  v-model="editingProduct.subFamilyName"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item label="名称" prop="name">
                <el-input v-model="editingProduct.name"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="updateBasic">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane name="keyWords" label="关键词">
          <div class="my-tags-box">
            <el-tag
              v-for="keyword in editingProduct.keywords"
              :key="keyword.id"
              closable
              :disable-transitions="false"
              @close="deleteKeyword(keyword.id)"
              >{{ keyword.term }}</el-tag
            >
            <el-input
              class="input-new-tag"
              v-if="keywordInputVisible"
              v-model="newKeyword"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
              >+ 新建</el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane name="specifications" label="规格">
          <div class="my-specifications">
            <el-table :data="editingProduct.specifications">
              <el-table-column label="规格名称" prop="name"> </el-table-column>
              <el-table-column label="对应货号" prop="number">
              </el-table-column>
              <el-table-column label="价格" prop="price"> </el-table-column>
              <el-table-column align="right">
                <template slot="header">
                  <el-button
                    icon="el-icon-plus"
                    type="primary"
                    size="mini"
                    @click="createSpecification"
                  ></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button
                    circle
                    size="mini"
                    type="success"
                    icon="el-icon-top"
                    :disabled="scope.row.orderInProduct === 1"
                    @click="
                      goUp('specifications', scope.row.productId, scope.row.id)
                    "
                  ></el-button>
                  <el-button
                    circle
                    size="mini"
                    type="success"
                    icon="el-icon-bottom"
                    :disabled="
                      scope.row.orderInProduct ===
                      editingProduct.specifications.length
                    "
                    @click="
                      goDown(
                        'specifications',
                        scope.row.productId,
                        scope.row.id
                      )
                    "
                  ></el-button>
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    @click="
                      deleteSpecification(
                        scope.row.productId,
                        scope.row.id,
                        scope.row.name
                      )
                    "
                  ></el-button>
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-edit"
                    @click="
                      editSpecification(scope.row.productId, scope.row.id)
                    "
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane name="images" label="图片">
          <div class="my-images">
            <el-table :data="editingProduct.images">
              <el-table-column label="序号" type="index"></el-table-column>
              <el-table-column label="产品图片" prop="name">
                <template slot-scope="scope">
                  <el-image :src="'/images/' + scope.row.name"></el-image>
                </template>
              </el-table-column>
              <el-table-column align="right">
                <template slot="header">
                  <el-button
                    icon="el-icon-plus"
                    type="primary"
                    size="mini"
                    @click="createProductImage"
                  ></el-button>
                </template>
                <template slot-scope="scope">
                  <el-button
                    circle
                    size="mini"
                    type="success"
                    icon="el-icon-top"
                    :disabled="scope.row.orderInProduct === 1"
                    @click="goUp('images', scope.row.productId, scope.row.id)"
                  ></el-button>
                  <el-button
                    circle
                    size="mini"
                    type="success"
                    icon="el-icon-bottom"
                    :disabled="
                      scope.row.orderInProduct === editingProduct.images.length
                    "
                    @click="goDown('images', scope.row.productId, scope.row.id)"
                  ></el-button>
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    @click="
                      deleteProductImage(
                        scope.row.productId,
                        scope.row.id,
                        scope.row.orderInProduct
                      )
                    "
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane name="document" label="说明书">
          <div class="my-form">
            <el-form
              ref="editingProductDocumentForm"
              :model="editingProduct.document"
              :rules="editingProductDocumentFormRules"
            >
              <el-form-item label="文件" prop="name">
                <el-upload
                  class="my-upload"
                  action="https://no-such-server"
                  accept=".pdf"
                  :http-request="onUploadDocument"
                  :show-file-list="false"
                >
                  <el-button
                    class="my-upload-button"
                    type="primary"
                    size="mini"
                    icon="el-icon-upload"
                    >上传</el-button
                  >
                </el-upload>
                <el-input
                  v-model="editingProduct.document.name"
                  readonly
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="saveDocument">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane name="details" label="详情">
          <div class="my-editor">
            <div id="detailEditor"></div>
            <el-button class="my-save-button" type="primary" @click="saveDetail"
              >保存</el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane name="practice" label="应用实例">
          <div class="my-practice-editor">
            <div id="practiceEditor"></div>
            <el-button
              class="my-save-button"
              type="primary"
              @click="savePractice"
              >保存</el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane name="faq" label="FAQ">
          <div class="my-faq-editor">
            <div id="faqEditor"></div>
            <el-button class="my-save-button" type="primary" @click="saveFaq"
              >保存</el-button
            >
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-dialog
      title="新增规格"
      :visible.sync="dialogNewVisible"
      @close="dialogNewClosed"
      width="450px"
    >
      <el-form
        :model="newSpecification"
        ref="newSpecificationForm"
        :rules="newSpecificationFormRules"
        label-width="80px"
      >
        <el-form-item label="规格名称" prop="name">
          <el-input
            v-model="newSpecification.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="对应货号" prop="number">
          <el-input
            v-model="newSpecification.number"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model.number="newSpecification.price"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogNewVisible = false">取消</el-button>
        <el-button type="primary" @click="addSpecification">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改规格"
      :visible.sync="dialogEditVisible"
      @close="dialogEditClosed"
      width="450px"
    >
      <el-form
        :model="editingSpecification"
        ref="editingSpecificationForm"
        :rules="editingSpecificationFormRules"
        label-width="80px"
      >
        <el-form-item label="规格名称" prop="name">
          <el-input
            v-model="editingSpecification.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="对应货号" prop="number">
          <el-input
            v-model="editingSpecification.number"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model.number="editingSpecification.price"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogEditVisible = false">取消</el-button>
        <el-button type="primary" @click="updateSpecification">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="新增图片"
      :visible.sync="dialogNewImageVisible"
      @close="dialogNewImageClosed"
      width="450px"
    >
      <el-form
        :model="newProductImage"
        ref="newProductImageForm"
        :rules="newProductImageFormRules"
      >
        <el-form-item label="图片" prop="name">
          <el-upload
            class="my-upload"
            action="https://no-such-server"
            accept=".png,.jpg"
            :http-request="onUpload"
            :show-file-list="false"
          >
            <el-button
              class="my-upload-button"
              type="primary"
              size="mini"
              icon="el-icon-upload"
              >上传</el-button
            >
          </el-upload>
          <el-image
            class="my-display-image"
            v-if="newProductImage.name"
            :src="'/images/' + newProductImage.name"
          ></el-image>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogNewImageVisible = false">取消</el-button>
        <el-button type="primary" @click="addProductImage">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import E from 'wangeditor'
import Func from '../commons/Function.vue'

export default {
  data () {
    const NumberValidator = (rule, value, callback) => {
      if (!value) {
        callback()
      }
      this.$http
        .post('/specifications/number-searches', {
          number: value
        })
        .then((res) => {
          if (res.data) {
            callback(new Error('货号不能重复'))
          } else {
            callback()
          }
        })
        .catch(() => {
          callback(new Error('网络错误'))
        })
    }
    const BrotherNumberValidator = (rule, value, callback) => {
      if (!value) {
        callback()
      }
      this.$http
        .post('/specifications/number-searches', { number: value })
        .then((res) => {
          if (res.data) {
            const specification = res.data
            if (specification.id === this.editingSpecification.id) {
              callback()
            }
            callback(new Error('货号不能重复'))
          } else {
            callback()
          }
        })
        .catch(() => {
          callback(new Error('网络错误'))
        })
    }
    return {
      activePane: 'basic',
      dialogNewVisible: false,
      dialogEditVisible: false,
      editingProduct: {
        name: '',
        document: {
          name: ''
        }
      },
      keywordInputVisible: false,
      newKeyword: '',
      editingProductRules: {
        displayImage: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        description: [
          { required: true, message: '请输入简介', trigger: 'blur' }
        ]
      },
      newSpecification: {
        productId: 0,
        name: ''
      },
      newSpecificationFormRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        number: [
          { required: true, message: '请输入货号', trigger: 'blur' },
          { validator: NumberValidator, trigger: 'blur' }
        ],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }]
      },
      editingSpecification: {},
      editingSpecificationFormRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        number: [
          { required: true, message: '请输入货号', trigger: 'blur' },
          { validator: BrotherNumberValidator, trigger: 'blur' }
        ],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }]
      },
      dialogNewImageVisible: false,
      newProductImage: {
        productId: 0,
        name: ''
      },
      newProductImageFormRules: {
        name: [{ required: true, message: '请上传图片', trigger: 'blur' }]
      },
      editingProductDocumentFormRules: {
        name: [{ required: true, message: '请上传文件', trigger: 'blur' }]
      },
      detailEditor: null,
      faqEditor: null,
      practiceEditor: null
    }
  },
  created () {},
  mounted () {
    const token = localStorage.access_token
    this.detailEditor = new E('#detailEditor')
    this.detailEditor.config.height = 1200
    this.detailEditor.config.zIndex = 0
    this.detailEditor.config.uploadImgServer = '/api/images/for-editor'
    this.detailEditor.config.uploadFileName = 'file'
    this.detailEditor.config.uploadImgHeaders = {
      Authorization: `Bearer ${token}`
    }
    this.detailEditor.create()
    this.faqEditor = new E('#faqEditor')
    this.faqEditor.config.height = 1200
    this.faqEditor.config.zIndex = 0
    this.faqEditor.config.uploadImgServer = '/api/images/for-editor'
    this.faqEditor.config.uploadFileName = 'file'
    this.faqEditor.config.uploadImgHeaders = {
      Authorization: `Bearer ${token}`
    }
    this.faqEditor.create()
    this.practiceEditor = new E('#practiceEditor')
    this.practiceEditor.config.height = 1200
    this.practiceEditor.config.zIndex = 0
    this.practiceEditor.config.uploadImgServer = '/api/images/for-editor'
    this.practiceEditor.config.uploadFileName = 'file'
    this.practiceEditor.config.uploadImgHeaders = {
      Authorization: `Bearer ${token}`
    }
    this.practiceEditor.create()
    this.getProduct()
  },
  methods: {
    async getProduct () {
      var id = Number(this.$route.params.id)
      await this.$http
        .get(`/products/${id}`)
        .then((res) => {
          this.editingProduct = res.data
          if (this.editingProduct.detail) {
            this.detailEditor.txt.html(this.editingProduct.detail.html)
          }
          if (this.editingProduct.faq) {
            this.faqEditor.txt.html(this.editingProduct.faq.html)
          }
          if (this.editingProduct.practice) {
            this.practiceEditor.txt.html(this.editingProduct.practice.html)
          }
          if (!this.editingProduct.document) {
            this.editingProduct.document = {
              productId: this.editingProduct.id,
              name: '',
              hash: ''
            }
          }
        })
        .catch((err) => {
          Func.ExceptionHandler(err, this.$router, this.$message)
        })
    },
    showInput () {
      this.keywordInputVisible = true
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm () {
      if (this.newKeyword) {
        this.$http
          .post(`/products/${this.editingProduct.id}/keywords`, {
            term: this.newKeyword
          })
          .then(() => {
            this.getProduct()
          })
          .catch(() => {
            this.getProduct()
          })
      }
      this.keywordInputVisible = false
      this.newKeyword = ''
    },
    async deleteKeyword (keywordId) {
      await this.$http
        .delete(`/products/${this.editingProduct.id}/keywords/${keywordId}`)
        .then(() => {
          this.getProduct()
        })
        .catch(() => {
          this.getProduct()
        })
    },
    onUpload (file) {
      const formData = new FormData()
      formData.append('file', file.file)
      this.$http
        .post('/images', formData)
        .then((res) => {
          this.newProductImage.name = res.data
          console.log(res.data)
          this.$message.success('上传成功')
          this.$refs.newProductImageForm.validateField('name')
        })
        .catch((err) => {
          this.$message.error(`${err.response.data.message}`)
        })
    },
    onUploadDocument (file) {
      const formData = new FormData()
      formData.append('file', file.file)
      this.$http
        .post('/documents', formData)
        .then((res) => {
          this.editingProduct.document.name = res.data.name
          this.editingProduct.document.hash = res.data.hash
          this.$message.success('上传成功')
          this.$refs.editingProductDocumentForm.validateField('name')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateBasic () {
      this.$refs.editingProductForm
        .validate()
        .then(() => {
          const id = this.editingProduct.id
          this.$http
            .patch(`/products/${id}/basic`, this.editingProduct)
            .then(() => {
              this.$message.success('修改成功')
            })
            .catch((err) => {
              this.$message.error(`${err.response.data.message}`)
            })
        })
        .catch(() => {})
    },
    createSpecification () {
      this.newSpecification.productId = this.editingProduct.id
      this.newSpecification.name = ''
      this.dialogNewVisible = true
    },
    async editSpecification (productId, id) {
      await this.$http
        .get(`/products/${productId}/specifications/${id}`)
        .then((res) => {
          this.editingSpecification = res.data
          this.dialogEditVisible = true
        })
        .catch(() => {})
    },
    dialogNewClosed () {
      this.newSpecification.name = ''
      this.$refs.newSpecificationForm.resetFields()
    },
    dialogEditClosed () {
      this.$refs.editingSpecificationForm.resetFields()
    },
    createProductImage () {
      this.newProductImage.productId = this.editingProduct.id
      this.dialogNewImageVisible = true
    },
    dialogNewImageClosed () {
      this.newProductImage.name = null
    },
    goUp (fieldName, productId, id) {
      this.$http
        .patch(`/products/${productId}/${fieldName}/${id}/up`)
        .then(() => {
          this.getProduct()
        })
        .catch(() => {
          this.getProduct()
        })
    },
    goDown (fieldName, productId, id) {
      this.$http
        .patch(`/products/${productId}/${fieldName}/${id}/down`)
        .then(() => {
          this.getProduct()
        })
        .catch(() => {
          this.getProduct()
        })
    },
    addSpecification () {
      this.$refs.newSpecificationForm
        .validate()
        .then(() => {
          this.$http
            .post(
              `/products/${this.newSpecification.productId}/specifications`,
              this.newSpecification
            )
            .then(() => {
              this.dialogNewVisible = false
              this.$message.success('新增成功')
              this.getProduct()
            })
            .catch((err) => {
              this.$message.error(`新增失败：${err.response.data.message}`)
            })
        })
        .catch(() => {})
    },
    addProductImage () {
      this.$refs.newProductImageForm
        .validate()
        .then(() => {
          this.$http
            .post(
              `/products/${this.newProductImage.productId}/images`,
              this.newProductImage
            )
            .then(() => {
              this.dialogNewImageVisible = false
              this.$message.success('新增成功')
              this.getProduct()
            })
            .catch((err) => {
              this.$message.error(`新增失败：${err.response.data.message}`)
            })
        })
        .catch(() => {})
    },
    updateSpecification () {
      this.$refs.editingSpecificationForm
        .validate()
        .then(() => {
          this.$http
            .patch(
              `/products/${this.editingSpecification.productId}/specifications/${this.editingSpecification.id}`,
              this.editingSpecification
            )
            .then(() => {
              this.dialogEditVisible = false
              this.$message.success('修改成功')
              this.getProduct()
            })
            .catch((err) => {
              this.$message.error(`修改失败：${err.response.data.message}`)
            })
        })
        .catch(() => {})
    },
    deleteSpecification (productId, id, name) {
      this.$confirm(`即将删除 ${name}`, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .delete(`/products/${productId}/specifications/${id}`)
            .then(() => {
              this.$message.success('删除成功')
              this.getProduct(this.$route.params.id)
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {
          this.$message.info('取消删除操作')
        })
    },
    deleteProductImage (productId, id, index) {
      this.$confirm(`即将删除 图片${index}`, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .delete(`/products/${productId}/images/${id}`)
            .then(() => {
              this.$message.success('删除成功')
              this.getProduct()
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {
          this.$message.info('取消删除操作')
        })
    },
    saveDetail () {
      const html = this.detailEditor.txt.html()
      const productDetail = { productId: this.editingProduct.id, html: html }
      this.$http
        .patch(`/products/${this.editingProduct.id}/detail`, productDetail)
        .then(() => {
          this.$message.success('保存成功')
          this.getProduct()
        })
        .catch((err) => {
          this.$message.error(`修改失败：${err.response.data.message}`)
        })
    },
    saveFaq () {
      const html = this.faqEditor.txt.html()
      const productFaq = { productId: this.editingProduct.id, html: html }
      this.$http
        .patch(`/products/${this.editingProduct.id}/faq`, productFaq)
        .then(() => {
          this.$message.success('保存成功')
          this.getProduct()
        })
        .catch((err) => {
          this.$message.error(`修改失败：${err.response.data.message}`)
        })
    },
    savePractice () {
      const html = this.practiceEditor.txt.html()
      const productPractice = { productId: this.editingProduct.id, html: html }
      this.$http
        .patch(`/products/${this.editingProduct.id}/practice`, productPractice)
        .then(() => {
          this.$message.success('保存成功')
          this.getProduct()
        })
        .catch((err) => {
          this.$message.error(`修改失败：${err.response.data.message}`)
        })
    },
    saveDocument () {
      this.$refs.editingProductDocumentForm
        .validate()
        .then(() => {
          this.$http
            .patch(
              `/products/${this.editingProduct.id}/document`,
              this.editingProduct.document
            )
            .then(() => {
              this.$message.success('保存成功')
              this.getProduct()
            })
            .catch((err) => {
              this.$message.error(`修改失败：${err.response.data.message}`)
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.my-content-box {
  width: 1200px;
  margin: 0 auto;
}
.el-breadcrumb {
  margin: 10px;
  font-size: 0.8em;
}
.my-form {
  width: 300px;
  margin-left: 100px;
}
.my-specifications {
  margin-left: 100px;
  width: 800px;
}
.my-images {
  margin-left: 100px;
  width: 600px;
}
.my-upload-button {
  margin-left: 15px;
}
.my-display-image {
  width: 300px;
}
.my-save-button {
  margin-top: 10px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.my-tags-box {
  margin-left: 100px;
}
</style>
