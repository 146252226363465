<template>
  <div class="my-content-box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }"
        ><span class="iconfont icon-home"></span
      ></el-breadcrumb-item>
      <el-breadcrumb-item>主页管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/carousel-images' }"
        >轮播图</el-breadcrumb-item
      >
      <el-breadcrumb-item>新建轮播图</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="my-form-box">
        <div class="my-form">
          <el-form
            ref="newCarouselImageForm"
            :model="newCarouselImage"
            :rules="newCarouselImageRules"
          >
            <el-form-item label="展示图" prop="displayImage">
              <el-upload
                class="my-upload"
                action="https://no-such-server"
                accept=".png,.jpg"
                :http-request="onUpload"
                :show-file-list="false"
              >
                <el-button
                  class="my-upload-button"
                  type="primary"
                  size="mini"
                  icon="el-icon-upload"
                  >上传</el-button
                >
              </el-upload>
              <img
                class="my-display-image"
                v-if="newCarouselImage.displayImage"
                :src="'/images/' + newCarouselImage.displayImage"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="create">确定</el-button>
              <el-button @click="cancel">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      newCarouselImage: {
        displayImage: ''
      },
      newCarouselImageRules: {
        displayImage: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ]
      }
    }
  },
  created () {},
  methods: {
    onUpload (file) {
      const formData = new FormData()
      formData.append('file', file.file)
      this.$http
        .post('/images', formData)
        .then((res) => {
          this.newCarouselImage.displayImage = res.data
          this.$message.success('上传成功')
          this.$refs.newCarouselImageForm.validateField('displayImage')
        })
        .catch((err) => {
          this.$message.error(`${err.response.data.message}`)
        })
    },
    cancel () {
      this.$router.go(-1)
    },
    create () {
      this.$refs.newCarouselImageForm
        .validate()
        .then(() => {
          this.$http
            .post('/carousel-images', this.newCarouselImage)
            .then(() => {
              this.$message.success('新增成功')
              this.$router.go(-1)
            })
            .catch((err) => {
              this.$message.error(`新增失败：${err.response.data.message}`)
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.my-content-box {
  width: 1200px;
  margin: 0 auto;
}
.el-breadcrumb {
  margin: 10px;
  font-size: 0.8em;
}
.my-form-box {
  display: flex;
  justify-content: center;
}
.my-form {
  width: 600px;
}
.my-upload-button {
  margin-left: 15px;
}
.my-display-image {
  width: 600px;
}
</style>
