import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import './assets/fonts/iconfont.css'
import axios from 'axios'
import moment from 'moment'

Vue.config.productionTip = false

axios.defaults.baseURL = '/api'
axios.interceptors.request.use(
  config => {
    if (config.headers.Authorization) {
      return config
    }
    const token = localStorage.access_token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  err => {
    return Promise.reject(err)
  })

Vue.prototype.$http = axios

Vue.filter('dateFormatter', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
