<template>
  <div class="login-page">
    <el-card>
      <div class="login-box">
        <div class="title">
        <img src="../assets/images/logo.png" />
        <div>后台管理系统</div>
      </div>
      <el-form
        class="login-form"
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginFormRules"
      >
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            prefix-icon="iconfont icon-user"
            spellcheck="false"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            type="password"
            prefix-icon="iconfont icon-password"
          ></el-input>
        </el-form-item>
        <div class="captcha-line">
          <el-form-item prop="captchaText">
            <el-input
              v-model="loginForm.captchaText"
              @keyup.enter.native="login"
              spellcheck="false"
            ></el-input>
          </el-form-item>
          <img
            class="captcha-img"
            :src="this.captcha.image"
            @click="getCaptcha"
          />
        </div>
        <el-button type="primary" @click="login">登录</el-button>
      </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      captcha: {},
      loginForm: {
        username: '',
        password: '',
        captchaText: ''
      },
      loginFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        captchaText: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 5, max: 5, message: '长度为5个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    localStorage.removeItem('access_token')
    this.getCaptcha()
  },
  methods: {
    async getCaptcha () {
      const { data: captcha } = await this.$http.get('/captcha')
      this.captcha = captcha
    },
    doLogin () {
      const formData = new FormData()
      formData.append('username', this.loginForm.username)
      formData.append('password', this.loginForm.password)
      formData.append('captcha_text', this.loginForm.captchaText)
      formData.append('captcha_id', this.captcha.id)
      formData.append('grant_type', 'password_captcha')
      formData.append('scope', 'all')
      this.$http
        .post('/oauth/token', formData, {
          headers: {
            Authorization: 'Basic ZnJvbnRlbmQ6ZnJvbnRlbmRfMTIzNDU2'
          }
        })
        .then((res) => {
          localStorage.setItem('access_token', res.data.access_token)
          localStorage.setItem('refresh_token', res.data.refresh_token)
          this.$router.go(-1)
        })
        .catch((err) => {
          this.$message.error(
            `登录失败：${err.response.data.error_description}`
          )
          this.getCaptcha()
        })
    },
    login () {
      this.$refs.loginFormRef
        .validate()
        .then(() => {
          this.doLogin()
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.login-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  margin-top: 18px;
  font-size: 20px;
  display: flex;
  align-items: center;
  img {
    height: 20px;
    margin-top: 2px;
    margin-right: 8px;
  }
}
.el-card {
  width: 400px;
  height: 350px;
}
.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.captcha-line {
  display: flex;
}
.captcha-img {
  height: 40px;
  margin-left: 10px;
  border-radius: 3px;
}
.el-button {
  width: 100%;
}
</style>
