<template>
  <div class="my-content-box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }"
        ><span class="iconfont icon-home"></span
      ></el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/products' }"
        >产品列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>新建产品</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="my-form-box">
        <div class="my-form">
          <el-form
            ref="newProductForm"
            :model="newProduct"
            :rules="newProductFormRules"
            label-width="80px"
          >
            <el-form-item label="所属系列" prop="family">
              <el-select v-model="newProduct.familyId" placeholder="请选择">
                <el-option
                  v-for="f in families"
                  :key="f.id"
                  :label="f.name"
                  :value="f.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="子系列" prop="subFamily">
              <el-select v-model="newProduct.subFamilyId" placeholder="请选择">
                <el-option
                  v-for="sf in subFamilies"
                  :key="sf.id"
                  :label="sf.name"
                  :value="sf.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品名称" prop="name">
              <el-input v-model="newProduct.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="create">确定</el-button>
              <el-button @click="cancel">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      families: [],
      subFamilies: [],
      newProduct: {
        familyId: null,
        subFamilyId: null,
        name: ''
      },
      newProductFormRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.getFamilies()
  },
  watch: {
    'newProduct.familyId': function (id) {
      this.families.forEach((f) => {
        if (f.id === id) {
          this.subFamilies = f.subFamilies
          this.newProduct.subFamilyId = f.subFamilies[0].id
        }
      })
    }
  },
  methods: {
    async getFamilies () {
      const { data: res } = await this.$http.get('product-families')
      this.families = res
      this.newProduct.familyId = this.families[0].id
      this.newProduct.subFamilyId = this.families[0].subFamilies[0].id
    },
    create () {
      this.$refs.newProductForm
        .validate()
        .then(() => {
          this.$http
            .post('/products', this.newProduct)
            .then(() => {
              this.$message.success('新增成功')
              this.$router.push('/products')
            })
            .catch((err) => {
              this.$message.error(`新增失败：${err.response.data.message}`)
            })
        })
        .catch(() => {})
    },
    cancel () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.my-content-box {
  width: 1200px;
  margin: 0 auto;
}
.el-breadcrumb {
  margin: 10px;
  font-size: 0.8em;
}
.my-form-box {
  display: flex;
  justify-content: center;
}
.my-form {
  width: 400px;
}
.el-select {
  width: 100%;
}
</style>
